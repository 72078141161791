import { useEffect, useState } from "react";
import { useRef } from "react";
import storage from "redux-persist/lib/storage";
import "./header.css";
import { Link, withRouter } from "react-router-dom";
import SideMenu from "./Sidemenu";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCart,
  getCartListAPI,
  moveWebinarTocartFromSaveLaterNew,
} from "../../../app/reducers/cart/cartSlice";
import { useHistory } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import PopoverContent from "react-bootstrap/PopoverContent";
import {
  Activeplan,
  getBlogCat1ListApi,
  getBlogCat2ListApi,
  getBlogCat3ListApi,
  getBlogCat4ListApi,
  getExploreDatas,
  getSearchData,
} from "../../../api/fetchClient";
import { getuserprofile } from "../../../api/fetchClient";
import { Affiliateget } from "../../../Affiliate/Api/AffiliateClient";
// images====>>>
import logo3 from "../../../images/Fingrad-fnl.png";
import cart1 from "../../../images/icon/Cart1.png";
import search1 from "../../../images/icon/Search1.svg";
import UserIcon from "../../../images/icon/userProfile.png";
import Editprofile from "../../../images/icon/edit profile.svg";
import corwnSub from "../../../images/icon/subscription icon.png";
import Help from "../../../images/icon/Help.png";
import Mycertificate from "../../../images/icon/certificates.svg";
import Savedcourse from "../../../images/icon/saved products.svg";
import Logout from "../../../images/icon/logout.svg";
import Search from "../../pages/searchpage/search";
import closeIcon from "../../../images/icon/closeIcon.svg";
import instrutor from "../../../images/icon/Instructor.svg";
import bronze from "../../../images/subscribe/basic plan-icon.png";
import silver from "../../../images/subscribe/premium plan-icon.png";
import gold from "../../../images/subscribe/elite plan-icon.png";
import Close from "../../../Affiliate/Images/icons/closeIcon.svg";
import headcell from "../../../images/icon/phone-call.svg";
import courseicn from "../../../images/PartnerPage/courses icon.png";
import webinaricn from "../../../images/PartnerPage/webinar icon.png";
import professionalicn from "../../../images/PartnerPage/professional program icon.png";
import learn from "../../../images/PartnerPage/learn map 2x.png";
import Whatsapp from "../../../images/WhatsApp Icon for FinGrad.png";

import crown from "../../../images/crown 2.png";
import affiliate from "../../../images/icon/Affiliate icon 1.png";
import partner from "../../../images/icon/partner icon 2x.png";
import FreeBtn from "./FreeBtn";

import bellIcon from "../../../images/NOTI BELL.png";
import NewReleaseModal from "./../../pages/NewReleaseModal";
import useBodyScrollLock from "./useBodyScrollLock";
import burgetIcon from "../../../images/burger-icon.svg";
import topbanner from "../../../images/Homepage/dec banner-min.png";
import "../../pages/newRelease.css";
import {
  getAllcourse,
  onBannnerClick,
} from "../../../app/reducers/course/courseSlice";

function NewHeader(props) {
  console.log(props, "proschecks");
  const bulkAnswer = JSON.parse(sessionStorage.getItem("bulkAnswer"));
  console.log(bulkAnswer, "sign up data");
  const dispatch = useDispatch();
  const getStoreData = useSelector(getAllcourse);
  console.log("The data from getStored data REDUX header", getStoreData);
  const userString = localStorage.getItem("user_data");
  const user = JSON.parse(userString);
  const [searchinput, setSearchinput] = useState("");
  const [searchitems, setSearchitems] = useState({});
  const [explore, setExplore] = useState([]);
  const [opensearchNav, setopensearchNav] = useState(false);
  const [picture, setPicture] = useState();
  const [totalCartItemNum, settotalCartItemNum] = useState(0);
  const [plandetails, setPlandetails] = useState();
  const [aff, setaff] = useState();
  const [isReleModalOpen, setIsReleModalOpen] = useState(false);
  let history = useHistory();
  const [isLocked, toggleLock] = useBodyScrollLock();
  const Subscribtions = JSON.parse(window.localStorage.getItem("Subscription"));
  //   dropdown popups =======>>>
  const [show, setShow] = useState(false);
  const [showSideBar, setShowSideBar] = useState();

  const inputref = useRef(null);

  const [showBanner, setShowBanner] = useState(true);
  const [showBlog, setShowBlog] = useState(false);
  const [blogCatList1, setBlogCatList1] = useState([]);
  const [blogCatList2, setBlogCatList2] = useState([]);
  const [blogCatList3, setBlogCatList3] = useState([]);
  const [blogCatList4, setBlogCatList4] = useState([]);
  console.log(blogCatList1, "blog list heder drop");
  const startDate = new Date();
  const endDate = new Date(Subscribtions?.subscription_ends);
  const banner_start = new Date(window.localStorage.getItem("bannerStart"));
  const banner_end = new Date(window.localStorage.getItem("bannerEnd"));
  // banner_end.setDate(banner_start.getDate()+1)
  var Difference_In_Time = endDate.getTime() - startDate.getTime();
  var Difference_In_Time_Header = banner_end.getTime() - banner_start.getTime();
  // console.log("diff start", banner_start, "diff_end", banner_end);
  // To calculate the no. of days between two dates
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  var Difference_In_Days_Header =
    Difference_In_Time_Header / (1000 * 3600 * 24);
  // console.log(
  //   "Days diff",
  //   Difference_In_Days_Header,
  //   "Time diff",
  //   Difference_In_Time_Header
  // );
  // console.log(Difference_In_Days.toFixed(0), "REMAINING DAYS FOR EXPRIRE");
  const [day1, setDay1] = useState();
  const [hour1, setHour1] = useState();
  const [minu1, setMinu1] = useState();
  const [sec1, setSec1] = useState();

  useEffect(() => {
    if (
      banner_start &&
      banner_end &&
      day1 === 0 &&
      hour1 === 0 &&
      minu1 === 0 &&
      sec1 === 0
    ) {
      const _today = new Date();
      const endDate = new Date();
      endDate.setDate(_today.getDate() + 2);
      window.localStorage.setItem("bannerStart", _today);
      window.localStorage.setItem("bannerEnd", endDate);
    }
  }, [banner_end, banner_start, day1, hour1, minu1, sec1]);

  const countDown1 = () => {
    const countDate1 = new Date(banner_end).getTime();
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const gap = countDate1 - now;

      const second = 1000;
      const minute = second * 60;
      const hour = minute * 60;
      const day = hour * 24;

      //caculation
      const textDay = Math.floor(gap / day);
      const textHour = Math.floor((gap % day) / hour);
      const textMinute = Math.floor((gap % hour) / minute);
      const textSecond = Math.floor((gap % minute) / second);

      if (gap > 0 && textHour < 24) {
        setDay1(textDay);
        setHour1(textHour);
        setMinu1(textMinute);
        setSec1(textSecond);
      } else {
        clearInterval(interval.current);
      }
    }, 1000);
  };
  const [day, setDay] = useState();
  const [hour, setHour] = useState();
  const [minu, setMinu] = useState();
  const [sec, setSec] = useState();
  const countDown = () => {
    const countDate = new Date(endDate).getTime();

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const gap = countDate - now;

      const second = 1000;
      const minute = second * 60;
      const hour = minute * 60;
      const day = hour * 24;

      //caculation
      const textDay = Math.floor(gap / day);
      const textHour = Math.floor((gap % day) / hour);
      const textMinute = Math.floor((gap % hour) / minute);
      const textSecond = Math.floor((gap % minute) / second);

      if (plandetails?.plan === "Free") {
        if (gap > 0 && textHour < 12) {
          setDay(textDay);
          setHour(textHour);
          setMinu(textMinute);
          setSec(textSecond);
        } else {
          clearInterval(interval.current);
        }
      }
    }, 1000);
  };

  useEffect(() => {
    const getData = async () => {
      await getBlogCat1ListApi().then((res) => {
        setBlogCatList1(res?.data);
      });
      await getBlogCat2ListApi().then((res) => {
        setBlogCatList2(res?.data);
      });
      await getBlogCat3ListApi().then((res) => {
        setBlogCatList3(res?.data);
      });
      await getBlogCat4ListApi().then((res) => {
        setBlogCatList4(res?.data);
      });
    };
    getData();
  }, []);
  useEffect(() => {
    // countDown();
    // countDown1();
    dispatch(onBannnerClick({ type: "OPEN_BNR" }));
  }, []);

  const onCloseBanner = () => {
    setShowBanner(false);
    dispatch(onBannnerClick({ type: "CLOSE_BNR" }));
  };

  const handleClick = (event) => {
    setShow(!show);
  };

  /*explore popup */

  const [isExploreOpen, setIsExploreOpen] = useState(false);

  const [programopen, setprogramopen] = useState(false);

  const onProgramClicked = (e) => {
    // if (programopen) {
    //   setprogramopen(false);
    // }
    // if (isProfilePopOpen) {
    //   setIsProfilePopOpen(false);
    // }
    e.stopPropagation();
    setIsProfilePopOpen(false);
    setIsExploreOpen(false);
    setShowBlog(false);
    setprogramopen((programopen) => !programopen);
  };
  const onBlogClicked = (e) => {
    // if (programopen) {
    //   setprogramopen(false);
    // }
    // if (isProfilePopOpen) {
    //   setIsProfilePopOpen(false);
    // }
    e.stopPropagation();
    setIsProfilePopOpen(false);
    setIsExploreOpen(false);
    setprogramopen(false);
    setShowBlog((showBlog) => !showBlog);
  };

  const onExploreClick = (e) => {
    e.stopPropagation();
    setIsProfilePopOpen(false);
    setprogramopen(false);
    setShowBlog(false);
    setIsExploreOpen((isExploreOpen) => !isExploreOpen);
  };

  /*user profile pop up section */

  const [isProfilePopOpen, setIsProfilePopOpen] = useState(false);

  const handleClicks = (e) => {
    e.stopPropagation();
    setIsExploreOpen(false);
    setprogramopen(false);
    setIsProfilePopOpen((isProfilePopOpen) => !isProfilePopOpen);
    console.log("profile open", isProfilePopOpen);
  };

  // ==========================>>>

  const cartData = useSelector(getAllCart);
  // setInterval(countDown(), 1000);
  // setInterval(countDown1(), 1000);
  useEffect(() => {
    dispatch(getAllCart);
    dispatch(getCartListAPI());
    settotalCartItemNum(cartData?.mainCartList?.items?.length);
  }, [cartData?.mainCartList?.items?.length]);

  const logout = () => {
    storage.removeItem("root");
    window.localStorage.clear();
    history.push("/");
    window.location.reload();
  };

  const inputsearch = async (e) => {
    setSearchinput(e.target.value);
    console.log(e.target.value);
    const resp = await getSearchData(e.target.value);
    setSearchitems(resp);
    console.log(resp);
  };

  useEffect(() => {
    const getdata = async () => {
      const get = await getuserprofile().then((resp) => {
        setPicture(resp.profile_pic);
      });
    };
    if (user?.access_token) {
      getdata();
    }
    const bodyStyle = document.body.style;

    bodyStyle.overflowY = searchinput === "" ? "auto" : "hidden";
  }, [searchinput]);

  useEffect(() => {
    const getData = async () => {
      const getExp = await getExploreDatas()
        .then((resp) => {
          setExplore(resp);
        })
        .catch((error) => {
          console.log(error.response);
        });

      const res = await Activeplan().then((resp) => {
        setPlandetails(resp?.data);

        console.log(resp?.data);
      });
      const affilia = await Affiliateget().then((resp) => {
        setaff(resp?.data);
      });
    };

    getData();
  }, []);

  // console.log(aff, "Affiliate");
  const closeMobSearchBar = () => {
    setSearchinput("");
    setopensearchNav(false);
  };

  const onHeaderClicked = () => {
    if (isExploreOpen) {
      setIsExploreOpen(false);
    }
    if (isProfilePopOpen) {
      setIsProfilePopOpen(false);
    }
    if (programopen) {
      setprogramopen(false);
    }
    if (showBlog) {
      setShowBlog(false);
    }
  };
  const checkUserAcccess = () => {
    if (user?.access_token) {
      props.history.push("/cart");
    } else {
      props.history.push({
        pathname: "/login",
        state: { redirect_url: props.history.location.pathname },
      });
    }
  };
  const LogoFun = (e) => {
    setSearchinput("");
    inputref.current.value = "";

    props.history.push("/");
  };

  const onReleseClick = () => {
    setIsReleModalOpen(!isReleModalOpen);
    toggleLock();
  };

  const onCloseModal = () => {
    setIsReleModalOpen(!isReleModalOpen);
    toggleLock();
  };

  // if (isReleModalOpen) {
  //   document.getElementById("root").style.overflowY= "hidden";

  // }
  const onHamburgerClick = () => {
    setShowSideBar(!showSideBar);
    setShow(false);
    toggleLock();
    console.log("clicked hamburger");
  };
  const courselink = () => {
    window.scrollTo(0, 0);
    history.push("/courses/?page=1&ordering=Popular");
  };

  const webinarlink = () => {
    window.scrollTo(0, 0);
    history.push("/webinar/?page=1&ordering=Popular");
  };

  const onMyLearninglink = () => {
    window.scrollTo(0, 0);
    history.push("/mylearning/inprogress");
  };

  const onUpFreeBtnlink = () => {
    window.scrollTo(0, 0);
    if (user?.access_token) {
      history.push("/subscribe");
    } else {
      props.history.push({
        pathname: "/signup",
        state: {
          redirect_url: "/subscribe",
          state: "Free",
        },
      });
    }
  };
  const onNewYearOfferClick = () => {
    localStorage.setItem("newYearOffer", true);
    if (user) {
      dispatch(
        moveWebinarTocartFromSaveLaterNew({
          category: "subscription",
          product_id: 3,
        })
      );
      history.push({
        pathname: "/cart",
      });
    } else {
      history.push({
        pathname: "/login",
        state: {
          category: "subscription",
          product_id: 3,
          redirect_url: "/cart",
        },
      });
    }
  };

  const onExplorelink = (type) => {
    window.scrollTo(0, 0);
    history.push(`/result/${type}`);
  };

  const onEditProfilelink = () => {
    window.scrollTo(0, 0);
    history.push("/edit-profile");
  };

  const onMycertiLink = () => {
    window.scrollTo(0, 0);
    history.push("/mylearning/compeleted");
  };

  const onContactLink = () => {
    window.scrollTo(0, 0);
    history.push("/Contact");
  };

  const onProfessional = () => {
    window.scrollTo(0, 0);
    history.push("/professional-programs");
  };
  const onLearmMap = () => {
    window.scrollTo(0, 0);
    history.push("/learnmap");
  };

  const onClaimClick = () => {
    localStorage.setItem("isClaimed", true);
    // if (user) {
    //   dispatch(
    //     moveWebinarTocartFromSaveLaterNew({
    //       category: "subscription",
    //       product_id: 3,
    //     })
    //   );
    //   history.push({
    //     pathname: "/cart",
    //   });
    // } else {
    //   history.push({
    //     pathname: "/login",
    //     state: {
    //       category: "subscription",
    //       product_id: 3,
    //       redirect_url: "/cart",
    //     },
    //   });
    // }
  };
  const popover = (
    <Popover id="popover-basic" style={{ zIndex: "6500" }}>
      <Popover.Content>
        <div className="d-flex align-items-center mt-2" onClick={courselink}>
          <img src={courseicn} alt="" />
          <div className="d-flex flex-column ml-3">
            <span>Courses</span>
            <small>Explore more courses and get certified</small>
          </div>
        </div>
        <div className="d-flex align-items-center mt-2" onClick={webinarlink}>
          <img src={webinaricn} alt="" />
          <div className="d-flex flex-column ml-3">
            <span>Webinars</span>
            <small>See all webinars taught by industry’s leading experts</small>
          </div>
        </div>
        <div
          className="d-flex align-items-center mt-2"
          onClick={onProfessional}>
          <img src={professionalicn} alt="" />
          <div className="d-flex flex-column ml-3">
            <span>Professional Programs</span>
            <small>
              Explore more Partnered-programs & Get professional certificate.{" "}
            </small>
          </div>
        </div>
      </Popover.Content>
    </Popover>
  );

  const svg1 = (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="4.48438"
        y="3.83594"
        width="23.8994"
        height="23.8994"
        rx="6.4"
        fill="#1576D0"
      />
      <path
        d="M16.6797 15.2109C16.8047 15.3359 16.9743 15.4062 17.1511 15.4062H17.5418C17.7185 15.4062 17.8881 15.3359 18.0131 15.2109L20.747 12.477C20.9702 12.2538 21.332 12.2538 21.5551 12.477L21.7043 12.6261C22.0642 12.9861 22.6798 12.7311 22.6798 12.222V10.173C22.6798 9.8574 22.4239 9.60156 22.1083 9.60156H20.0593C19.5502 9.60156 19.2953 10.2171 19.6552 10.5771L19.8044 10.7262C20.0275 10.9493 20.0275 11.3111 19.8044 11.5343L17.7505 13.5882C17.5273 13.8113 17.1655 13.8113 16.9424 13.5882L15.3464 11.9922C15.2214 11.8672 15.0519 11.7969 14.8751 11.7969H14.4844C14.3076 11.7969 14.1381 11.8672 14.0131 11.9922L11.0556 14.7656C10.9404 14.8736 10.875 15.0245 10.875 15.1824V15.576C10.875 16.0821 11.4844 16.3384 11.8461 15.9844L14.2757 13.6063C14.4996 13.3873 14.858 13.3892 15.0795 13.6106L16.6797 15.2109Z"
        fill="white"
      />
      <path
        d="M22.875 22.3583C22.875 22.6739 22.6192 22.9297 22.3036 22.9297H21.4464C21.1308 22.9297 20.875 22.6739 20.875 22.3583V15.5011C20.875 15.1855 21.1308 14.9297 21.4464 14.9297H22.3036C22.6192 14.9297 22.875 15.1855 22.875 15.5011V22.3583ZM19.5417 22.3583C19.5417 22.6739 19.2858 22.9297 18.9702 22.9297H18.1131C17.7975 22.9297 17.5417 22.6739 17.5417 22.3583V17.8344C17.5417 17.5189 17.7975 17.263 18.1131 17.263H18.9702C19.2858 17.263 19.5417 17.5189 19.5417 17.8344V22.3583ZM16.2083 22.3583C16.2083 22.6739 15.9525 22.9297 15.6369 22.9297H14.7798C14.4642 22.9297 14.2083 22.6739 14.2083 22.3583V16.8344C14.2083 16.5189 14.4642 16.263 14.7798 16.263H15.6369C15.9525 16.263 16.2083 16.5189 16.2083 16.8344V22.3583ZM12.875 22.3583C12.875 22.6739 12.6192 22.9297 12.3036 22.9297H11.4464C11.1308 22.9297 10.875 22.6739 10.875 22.3583V19.5011C10.875 19.1855 11.1308 18.9297 11.4464 18.9297H12.3036C12.6192 18.9297 12.875 19.1855 12.875 19.5011V22.3583Z"
        fill="white"
      />
    </svg>
  );
  const svg2 = (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="6"
        y="6"
        width="23.625"
        height="23.625"
        rx="3.375"
        fill="#923AEA"
      />
      <path
        d="M24.7159 12.75C23.6037 12.75 22.9824 13.965 23.2355 14.8678L20.5125 17.8716C20.2824 17.7956 19.9449 17.7956 19.7148 17.8716L17.7588 15.72C18.0196 14.8172 17.3983 13.5938 16.2784 13.5938C15.1662 13.5938 14.5372 14.8088 14.798 15.72L11.3003 19.5591C10.4795 19.2806 9.375 19.9641 9.375 21.1875C9.375 22.1156 10.0653 22.875 10.9091 22.875C12.0213 22.875 12.6426 21.66 12.3895 20.7572L15.8795 16.9097C16.1097 16.9856 16.4472 16.9856 16.6773 16.9097L18.6332 19.0613C18.3724 19.9641 18.9938 21.1875 20.1136 21.1875C21.2259 21.1875 21.8548 19.9725 21.594 19.0613L24.3247 16.0659C25.1455 16.3444 26.25 15.6609 26.25 14.4375C26.25 13.5094 25.5597 12.75 24.7159 12.75Z"
        fill="white"
      />
    </svg>
  );
  const svg3 = (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="4.67188"
        y="4.09375"
        width="23.8994"
        height="23.8994"
        rx="6.4"
        fill="#04A96D"
      />
      <path
        d="M11.33 20.13L14.06 17.4L16.02 19.36L19.24 16.14L20.5 17.4V13.9H17L18.26 15.16L16.02 17.4L14.06 15.44L10.63 18.87C10.21 17.96 10 16.98 10 16C10 12.15 13.15 9 17 9C20.85 9 24 12.15 24 16C24 19.85 20.85 23 17 23C14.76 23 12.66 21.95 11.33 20.13V20.13Z"
        fill="white"
      />
    </svg>
  );
  const svg4 = (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="6.75"
        y="6.75"
        width="23.625"
        height="23.625"
        rx="3.375"
        fill="#E26E03"
      />
      <rect
        x="11.8359"
        y="19.7578"
        width="2.88"
        height="6.48"
        rx="0.72"
        fill="white"
      />
      <rect
        x="16.875"
        y="15.4375"
        width="2.88"
        height="10.8"
        rx="0.72"
        fill="white"
      />
      <rect
        x="21.9141"
        y="11.1094"
        width="2.88"
        height="15.12"
        rx="0.72"
        fill="white"
      />
    </svg>
  );

  const popupbg = (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 1440 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="1440" height="70" fill="url(#paint0_linear_10993_18979)" />
      <defs>
        <linearGradient
          id="paint0_linear_10993_18979"
          x1="6"
          y1="35.0005"
          x2="1435"
          y2="35.0004"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFE768" />
          <stop offset="1" stop-color="#FCAB4C" />
        </linearGradient>
      </defs>
    </svg>
  );

  const bilty = () => {
    history.push("/december-challenge");
  };

  return (
    <div style={{ top: 0, position: "sticky", zIndex: 5000 }}>
      {/* {Subscribtions?.plan === "Free" || Subscribtions === "" || !user ? (
        <div class="marquee">
          <Link to="/subscribe">
            <span
              className="banner-offer-text"
              style={{ fontFamily: "montserrat" }}
            >
              <img
                style={{ width: "32px", height: "32px" }}
                src={bellIcon}
              ></img>
              &nbsp; Introductory 50% off.
              <b style={{ color: "#F3E560" }}> &nbsp; Subscribe Now </b>
            </span>
          </Link>
        </div>
      ) : (
        ""
      )} */}
      {console.log(
        "is_subscription_ended",
        Subscribtions?.is_subscription_ended,
        "plan",
        Subscribtions?.plan
      )}
      {/* {Subscribtions?.plan === "" || Subscribtions === "" || !user ? ( */}
      <div class="new-release-fevstiv-offer-wrap">
        <span className="banner-offer-text">
          <span>Sit Tight! We are cooking up something new for you.</span>
        </span>
        {/* <button onClick={onNewYearOfferClick} className="claim-btn">
            Claim Now
          </button> */}
      </div>
      {/* ) : (
        ""
      )} */}

      {/* <div className="dec-event-popwrap">
          <span>December Learning Challenge</span>
          <a href="https://bit.ly/fgdc2022" className="dec-evnt-popbtn" >Check the rules</a>
      </div> */}

      <header onClick={onHeaderClicked} className="header-all">
        {opensearchNav ? (
          <div className="headertop d-flex justify-content-center align-items-center">
            <div className="mob-search-box px-3">
              <img
                src={search1}
                style={{ width: "20px", height: "20px" }}
                alt="search"
              />
              <input
                style={{ border: "none", background: "transparent" }}
                className="w-100 h-100 mx-2"
                // autoComplete="new-password"
                type="text"
                placeholder="search"
                onChange={inputsearch}
                value={searchinput}
              />
              <img
                src={closeIcon}
                style={{ width: "15px", height: "15px" }}
                onClick={closeMobSearchBar}
                alt="close"
              />
            </div>
          </div>
        ) : (
          <div className="headertop">
            <div className="d-flex ">
              <div /* onClick={LogoFun} */>
                <Link to="/">
                  {" "}
                  <img className="logo-bx " src={logo3} alt="logo" />
                </Link>
              </div>
              <input
                className="searchinput"
                // autoComplete="new-password"
                type="search"
                ref={inputref}
                placeholder="Search"
                onChange={inputsearch}
              />
            </div>

            <div className="searchbox-icon">
              <img
                style={{ width: "20px", height: "20px" }}
                src={search1}
                alt="..."
                onClick={() => {
                  setopensearchNav(true);
                  props?.closehandle();
                }}
              />
            </div>
            <div className="mob-cellicon">
              {" "}
              <a
                href={"https://wa.me/918050106555"}
                style={{ color: "#007A78" }}>
                <img src={Whatsapp} alt="" />
              </a>
            </div>
            <img
              className="mobile-hamburger-icon"
              onClick={onHamburgerClick}
              src={burgetIcon}
            />
            {showSideBar && (
              <SideMenu
                blogCatList1={blogCatList1}
                blogCatList2={blogCatList2}
                blogCatList3={blogCatList3}
                blogCatList4={blogCatList4}
                plandetails={plandetails}
                show={show}
                onUpFreeBtnlink={onUpFreeBtnlink}
                onMobExpClick={handleClick}
                aff={aff}
                onHamburgerClick={onHamburgerClick}
                topNotiBanner={
                  Subscribtions?.plan === "Free" ||
                  Subscribtions === "" ||
                  Subscribtions?.plan === "Basic" ||
                  Subscribtions?.plan === "Premium" ||
                  !user
                }
              />
            )}

            <div className="d-flex">
              <div className="d-flex align-items-center navbarlink">
                <li>
                  <a
                    href="https://wa.me/918050106555"
                    style={{ color: "#007A78" }}>
                    <img
                      src={Whatsapp}
                      alt=""
                      style={{ width: "25px", height: "25px" }}
                    />
                  </a>
                </li>
                {user?.access_token ? (
                  <li
                    /* onClick={onMyLearninglink}  */ className="header-nav-hov">
                    <Link to="/mylearning/inprogress">My Learning</Link>
                  </li>
                ) : (
                  ""
                )}

                <li id="explore-head">
                  <div>
                    <p>Explore</p>

                    {/* {isExploreOpen && ( */}
                    {/* <div
                      className="explore-popup-wrapper"
                      onClick={onExploreClick}
                    > */}
                    <div className="explore-pop-up">
                      <div className="exp-popup-col pop-content-wrap">
                        <div className="pop-body">
                          <div className="Explore-div-main">
                            {explore?.map((items) => (
                              <ul>
                                <li
                                  onClick={() =>
                                    onExplorelink(items.category_type)
                                  }
                                  key={items.id}>
                                  <Link
                                  // to={`/result/${items.category_type}`}
                                  >
                                    <a>{items.category_type}</a>
                                  </Link>
                                </li>
                              </ul>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                    {/* )} */}
                  </div>
                </li>

                {/* professional program  */}
                <li id="program-head">
                  <div>
                    <span>Programs</span>

                    {/* {programopen && ( */}
                    {/* <div className="user-profile-pop-wrapper">
                        <div
                          className={`program-wraper-head ${
                            (Subscribtions?.plan === "Free" &&
                              Subscribtions?.is_freetrial_used) ||
                            !user
                              ? "program-wraper-head-align"
                              : ""
                          }`}
                          onClick={onProgramClicked}
                        > */}
                    <div className="popup-program-in ">
                      <div
                        className="d-flex align-items-center mt-2"
                        onClick={courselink}>
                        <img src={courseicn} alt="" />
                        <div className="d-flex flex-column ml-3">
                          <span>Courses</span>
                          <small>Explore more courses and get certified</small>
                        </div>
                      </div>
                      <div
                        className="d-flex align-items-center mt-2"
                        onClick={webinarlink}>
                        <img src={webinaricn} alt="" />
                        <div className="d-flex flex-column ml-3">
                          <span>Webinars</span>
                          <small>
                            See all webinars taught by industry’s leading
                            experts
                          </small>
                        </div>
                      </div>
                      {/* <div
                              className="d-flex align-items-center mt-2"
                              onClick={onProfessional}
                            >
                              <img src={professionalicn} alt="" />
                              <div className="d-flex flex-column ml-3">
                                <span>Professional Programs</span>
                                <small>
                                  Explore more Partnered-programs & Get
                                  professional certificate.{" "}
                                </small>
                              </div>
                            </div> */}
                      <div
                        className="d-flex align-items-center mt-2"
                        onClick={onLearmMap}>
                        <img src={learn} alt="" />
                        <div className="d-flex flex-column ml-3">
                          <span>Learn Map</span>
                          <small>
                            Step by step structured plan to start your learning
                            journey
                          </small>
                        </div>
                      </div>
                    </div>
                    {/* </div>
                      </div> */}
                    {/* )} */}
                  </div>
                </li>

                <li className="header-nav-hov new-feature-tag-new">
                  <Link to="/resources/ebooks/">Resources</Link>
                  {/* <p>New</p> */}
                </li>

                {/* blog */}
                <li id="blog-head">
                  <div>
                    <a
                      href="https://blog.joinfingrad.com/"
                      onClick={onBlogClicked}>
                      Blog
                    </a>

                    <div className="blog-inner-wrap">
                      <div className="row">
                        <div className="col-6 ">
                          <div className="blog-cat-sec">
                            <div>{svg1}</div>
                            <div className="blog-cat-info-right">
                              <h2>Investing</h2>
                              {blogCatList1?.slice(0, 3)?.map((each) => {
                                return (
                                  <a href={each?.link}>
                                    {each?.yoast_head_json?.title}
                                  </a>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="col-6 ">
                          <div className="blog-cat-sec">
                            <div>{svg2}</div>
                            <div className="blog-cat-info-right">
                              <h2>Trading</h2>
                              {blogCatList2?.slice(0, 3)?.map((each) => {
                                return (
                                  <a href={each?.link}>
                                    {each?.yoast_head_json?.title}
                                  </a>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="col-6 ">
                          <div className="blog-cat-sec">
                            <div>{svg3}</div>
                            <div className="blog-cat-info-right">
                              <h2>Mutual Funds</h2>
                              {blogCatList3?.slice(0, 3)?.map((each) => {
                                return (
                                  <a href={each?.link}>
                                    {each?.yoast_head_json?.title}
                                  </a>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="col-6 ">
                          <div className="blog-cat-sec">
                            <div>{svg4}</div>
                            <div className="blog-cat-info-right">
                              <h2>Stock Market Basics</h2>
                              {blogCatList4?.slice(0, 3)?.map((each) => {
                                return (
                                  <a href={each?.link}>
                                    {each?.yoast_head_json?.title}
                                  </a>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="header-nav-hov">
                  <Link to="/subscribe">Pricing</Link>
                </li>

                {user?.access_token ? (
                  <li>
                    <div
                      style={{ cursor: "pointer" }}
                      // onClick={checkUserAcccess}
                    >
                      <Link to="/cart">
                        <img className="cart-btn" src={cart1} alt="logo" />
                      </Link>
                    </div>
                  </li>
                ) : (
                  ""
                )}
                {user?.access_token ? (
                  <div className="cart-link">
                    <Link to="/cart">
                      <div className={totalCartItemNum > 0 ? "cart-num" : ""}>
                        {totalCartItemNum > 0 ? totalCartItemNum : ""}
                      </div>
                    </Link>
                  </div>
                ) : (
                  ""
                )}
                {/* {FREE_BTN} */}

                {user?.access_token ? (
                  <li style={{ cursor: "pointer" }} className="pr-0">
                    {picture === null ? (
                      <div className="pos-rel">
                        <img
                          id="profile-img"
                          onClick={handleClicks}
                          src={UserIcon}
                          className="user-profile1 pos-rel"
                          alt=""></img>
                        <div className="plan-logo-user">
                          {Subscribtions?.is_subscription_ended === true ? (
                            ""
                          ) : Subscribtions?.plan === "Basic" ? (
                            <img src={bronze} />
                          ) : Subscribtions?.plan === "Premium" ? (
                            <img src={silver} />
                          ) : Subscribtions?.plan === "Elite" ? (
                            <img src={gold} />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="pos-rel">
                        <img
                          id="profile-img"
                          src={picture}
                          className="user-profile1 pos-rel"
                          onClick={
                            handleClicks
                          } /* onerror="this.onerror=null; this.src={UserIcon}" */
                        />

                        <div className="plan-logo-user">
                          {Subscribtions?.is_subscription_ended === true ? (
                            ""
                          ) : Subscribtions?.plan === "Basic" ? (
                            <img src={bronze} />
                          ) : Subscribtions?.plan === "Premium" ? (
                            <img src={silver} />
                          ) : Subscribtions?.plan === "Elite" ? (
                            <img src={gold} />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    )}
                    {/* <Overlay
                    show={logpop}
                    target={poptarget}
                    placement="bottom"
                    container={refs.current}
                    containerPadding={20}
                  >
                    <Popover
                      id="popover-contained"
                      style={{
                        width: "fit-content",
                        height: "fit-content",
                        zIndex: 6000,
                      }}
                    > */}
                    {/* <Popover.Content> */}

                    {isProfilePopOpen && (
                      <div className="user-profile-pop-wrapper">
                        <div className="user-profile-pop-up-sec">
                          <ul className="userdrop-down">
                            {aff?.is_verified === "accepted" &&
                            user?.user?.user_type === "affiliate" ? (
                              <Link
                                to={
                                  aff?.is_tc_accepted === true
                                    ? "/affiliates/dashboard"
                                    : "/affiliates"
                                }>
                                <li
                                  className="pb-md-2 profile-pop-up-text"
                                  style={{ fontWeight: "600" }}>
                                  {" "}
                                  <img
                                    className="my-auto mr-md-3"
                                    style={{ width: "30px", height: "28px" }}
                                    src={affiliate}
                                    alt=""
                                  />
                                  <a>Affiliate</a>
                                </li>
                              </Link>
                            ) : (
                              ""
                            )}
                            {user.user.user_type === "instructor" && (
                              <Link to="/instructor">
                                <li
                                  className="pb-md-2 profile-pop-up-text"
                                  style={{ fontWeight: "600" }}>
                                  {" "}
                                  <img
                                    className="my-auto mr-md-3"
                                    style={{ width: "30px", height: "28px" }}
                                    src={instrutor}
                                    alt=""
                                  />
                                  <a>Instructor</a>
                                </li>
                              </Link>
                            )}

                            {user.user.user_type === "admin" && (
                              <Link to="/admin">
                                <li
                                  className="profile-pop-up-instructor-text pb-md-2"
                                  style={{ fontWeight: "600" }}>
                                  Admin
                                </li>
                              </Link>
                            )}
                            {user.user.user_type === "sales_executive" && (
                              <Link to="/sales-executive/dashboard">
                                <li
                                  className="profile-pop-up-instructor-text pb-md-2"
                                  style={{ fontWeight: "600" }}>
                                  Sale Executive DashBoard
                                </li>
                              </Link>
                            )}
                            {user.user.user_type === "sales_manager" && (
                              <Link to="/sales-manager/dashboard">
                                <li
                                  className="profile-pop-up-instructor-text pb-md-2"
                                  style={{ fontWeight: "600" }}>
                                  Sale Manager DashBoard
                                </li>
                              </Link>
                            )}
                            {user.user.user_type === "partner" && (
                              <Link to="/partner">
                                <li
                                  className="pb-md-2 profile-pop-up-text"
                                  style={{ fontWeight: "600" }}>
                                  <img
                                    className="my-auto mr-md-3"
                                    style={{ width: "30px", height: "28px" }}
                                    src={partner}
                                    alt=""
                                  />
                                  <a>Partner</a>
                                </li>
                              </Link>
                            )}
                            {/* <Link to="/edit-profile"> */}
                            <li
                              onClick={onEditProfilelink}
                              className="pb-md-2 profile-pop-up-text">
                              <img
                                className="my-auto mr-md-3"
                                style={{ width: "22px", height: "22px" }}
                                src={Editprofile}
                                alt=""
                              />
                              <a>Edit Profile</a>
                            </li>
                            {/* </Link> */}

                            {/*                             {plandetails?.plan === "Basic" ||
                            plandetails?.plan === "Premium" ||
                            plandetails?.plan === "Elite" ? ( */}
                            {/* <Link to="/subscribe"> */}
                            <li
                              onClick={onUpFreeBtnlink}
                              className="pb-md-2 profile-pop-up-text">
                              <img
                                className="my-auto mr-md-3"
                                style={{ width: "22px", height: "22px" }}
                                src={corwnSub}
                                alt=""
                              />
                              <a>My Subscription</a>
                            </li>
                            {/* </Link> */}
                            {/*  ) : (
                              ""
                            )} */}

                            {/* <Link to="/mylearning/compeleted"> */}
                            <li
                              onClick={onMycertiLink}
                              className="pb-md-2 profile-pop-up-text">
                              <img
                                className="my-auto mr-md-3"
                                style={{ width: "22px", height: "22px" }}
                                src={Mycertificate}
                                alt=""
                              />
                              <a>My Certificates</a>
                            </li>
                            {/* </Link> */}
                            {/* <Link to="/mylearning/savedproducts">
                              {" "}
                              <li className="pb-md-2 profile-pop-up-text">
                                <img
                                  className="my-auto mr-md-3"
                                  style={{ width: "22px", height: "22px" }}
                                  src={Savedcourse}
                                  alt=""
                                />
                                <a>Saved Courses</a>
                              </li>
                            </Link> */}
                            <Link>
                              <li
                                onClick={onContactLink}
                                className="pb-md-2 profile-pop-up-text">
                                <img
                                  className="my-auto mr-md-3"
                                  style={{ width: "22px", height: "22px" }}
                                  src={Help}
                                  alt=""
                                />
                                <a>Help</a>
                              </li>
                            </Link>
                            <li
                              className="profile-pop-up-text"
                              onClick={logout}>
                              <img
                                className="my-auto mr-md-3"
                                style={{ width: "22px", height: "20px" }}
                                src={Logout}
                                alt=""
                              />
                              <a>Logout</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}

                    {/* </Popover.Content>
                    </Popover>
                  </Overlay> */}
                  </li>
                ) : (
                  // <Link >
                  <button
                    // onClick={() =>
                    //   props.history.push({
                    //     pathname:
                    //       window.location.pathname === "/login"
                    //         ? "/signup"
                    //         : window.location.pathname === "/signup"
                    //         ? "/login"
                    //         : "/login",
                    //     state: props.location.state?.ansResponse?.quiz
                    //       ? { id: bulkAnswer?.quiz }
                    //       : { redirect_url: props.location.pathname },
                    //   })
                    // }
                    onClick={() => props.history.push(`/login`)}
                    className="loginbtn" /* onClick={() => setmodalLoginShow(true)} */
                  >
                    {/* {window.location.pathname === "/login"
                      ? "Sign Up"
                      : window.location.pathname === "/signup"
                      ? "Log In"
                      : "Log In"} */}{" "}
                    Log In
                  </button>
                  // </Link>
                )}
                <FreeBtn onUpFreeBtnlink={onUpFreeBtnlink} />

                {/* < MydModalWithGrid show={modalLoginShow} onClose={onClosemodal}  /> */}
              </div>
            </div>
          </div>
        )}

        {Subscribtions?.is_subscription_ended === true &&
        getStoreData?.showBan === true ? (
          <div className="plan-expire-banner coupon-banner">
            <Link to="/subscribe">
              {" "}
              {Subscribtions?.plan === "Free"
                ? `${Subscribtions?.plan} Trial`
                : Subscribtions?.plan}{" "}
              {Subscribtions?.plan === "Free" ? "" : "Plan"} expired. Use code{" "}
              <span>NY40</span> to get 40% off on Elite Plan.
              <span>
                {Subscribtions?.plan === "Free"
                  ? " Upgrade Now"
                  : " Renew Plan"}{" "}
              </span>
            </Link>
            <img onClick={onCloseBanner} src={Close} alt="" />
          </div>
        ) : (
          ""
        )}

        {Subscribtions?.is_freetrial_used === true &&
        Subscribtions?.is_subscription_ended === false &&
        getStoreData?.showBan === true &&
        Subscribtions?.plan === "Free" ? (
          <div className="plan-expire-banner">
            <Link to="/subscribe">
              {" "}
              {Subscribtions?.plan === "Free" && "Free Trial  "}
              ends in
              {/* <span className="day">{day}d</span> */}
              {Difference_In_Days.toFixed(0) === "0" && (
                <span>
                  <span className="hour">{hour}h</span>
                  <span className="minute">{minu}m</span>
                  <span className="second">{sec}s</span>
                </span>
              )}
              {Difference_In_Days.toFixed(0) !== "0" && (
                <span>
                  {" "}
                  {Difference_In_Days.toFixed(0)}{" "}
                  {Difference_In_Days.toFixed(0) === "1" ? "day" : "days"}
                </span>
              )}
              <span>
                {Subscribtions?.plan === "Free"
                  ? " Upgrade Now"
                  : " Renew Plan"}{" "}
              </span>
            </Link>
            <img onClick={onCloseBanner} src={Close} alt="" />
          </div>
        ) : (
          ""
        )}

        <div className="Search-value-div">
          {searchinput && (
            <Search
              setSearchKey={setSearchinput}
              searchKey={searchinput}
              data={searchitems}
            />
          )}
        </div>
      </header>
    </div>
  );
}

export default withRouter(NewHeader);
