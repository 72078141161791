import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { get, post, del, put } from "../../api/MainClient";
import produce from "@reduxjs/toolkit/node_modules/immer";

export const getAssessmentData = createAsyncThunk(
  "finalAssessmentQuiz/getAssessmentData",
  async (id) => {
    const response = await get(`/quiz/update-final-assessment/${id}/`).then(
      (resp) => {
        return resp.data;
      }
    );
    return response;
  }
);
// update quiz data
export const updateFinalAssessmentData = createAsyncThunk(
  "finalAssessmentQuiz/updateFinalAssessmentData",
  async (data) => {
    const response = await put(
      `/quiz/update-final-assessment/${data.id}/`,
      data.data
    ).then((resp) => {
      return resp.data;
    });
    return response;
  }
);
export const finalAssessmentSlice = createSlice({
  name: "finalAssessmentQuiz",
  initialState: {
    quizArr: [
      {
        question: "",
        image: "",
        points: 1,
        negative_mark: 0,
        single_answer: true,
        answers: [],
      },
    ],
  },
  reducers: {
    addAssessmentQuiz: (state, { payload }) => {
      if (state.quizArr[state.quizArr.length - 1].question !== "") {
        const data = {
          question: "",
          image: "",
          points: 1,
          negative_mark: 0,
          single_answer: true,

          answers: [],
        };
        state.quizArr = [...state.quizArr, data];
      }
    },
    deleteAssessmentQuestion: (state, { payload }) => {
      state.quizArr = state.quizArr.filter((item, i) => i !== payload);
    },
    clearAssessment: (state, { payload }) => {
      return (state = {
        quizArr: [
          {
            question: "",
            image: "",
            points: 1,
            negative_mark: 0,
            single_answer: true,

            answers: [],
          },
        ],
      });
    },
    editAssessmentOptionReducer: (state, { payload }) => {
      const nextstate = produce(current(state.quizArr), (draft) => {
        if (payload.keyName === "delete") {
          draft[payload.questionIndex].answers = draft[
            payload.questionIndex
          ].answers.filter((item, i) => i !== payload.optionIndex);
        } else if (
          payload.keyName === "question" ||
          payload.keyName === "image" ||
          payload.keyName === "points" ||
          payload.keyName === "negative_mark" ||
          payload.keyName === "single_answer"
        ) {
          draft[payload.questionIndex][payload.keyName] = payload.value;
        } else if (payload.keyName === "is_valid") {
          if (draft[payload.questionIndex].single_answer) {
            console.log(
              "enterd********************",
              draft[payload.questionIndex].answers.length
            );

            for (
              let i = 0;
              i < draft[payload.questionIndex].answers.length;
              i++
            ) {
              draft[payload.questionIndex].answers[i].is_valid =
                payload.optionIndex === i ? true : false;
            }
          } else {
            draft[payload.questionIndex].answers[payload.optionIndex].is_valid =
              payload.value;
          }
          console.log(
            draft[payload.questionIndex].answers[payload.optionIndex].is_valid,
            "redux value"
          );
        } else {
          draft[payload.questionIndex].answers[payload.optionIndex][
            payload.keyName
          ] = payload.value;
        }
      });
      return { ...state, quizArr: nextstate };
    },
    addAssessmentOptionReducer: (state, { payload }) => {
      const nextstate = produce(current(state.quizArr), (draft) => {
        draft[payload.questionIndex].answers.push(payload.value);
      });
      return { ...state, quizArr: nextstate };
    },
    addAssessmentQuestion: (state, { payload }) => {
      return { ...state, quizArr: [...state.quizArr, payload] };
    },
    handleSingleAnswerChange: (state, action) => {
      const { questionIndex, newValue } = action.payload;
      state.quizArr[questionIndex].single_answer = newValue;
    },
  },
  extraReducers: {
    [getAssessmentData.fulfilled]: (state, { payload }) => {
      const quizData = payload.reverse().map((item) => item.quiz);
      return { ...state, quizArr: quizData };
    },
    [updateFinalAssessmentData.fulfilled]: (state, { payload }) => {
      // return {...state,quizArr:[]}
      state = { quizArr: [] };
    },
  },
});
export const {
  addAssessmentQuiz,
  deleteAssessmentQuestion,
  clearAssessment,
  editAssessmentOptionReducer,
  addAssessmentOptionReducer,
  addAssessmentQuestion,
  updateSingleAnswer,
} = finalAssessmentSlice.actions;
export const getAssessmentQuizStore = (state) => state.finalAssessmentQuiz;
export default finalAssessmentSlice.reducer;
